import { api, apiWithkey } from "./axios";
import {
  baseApiURL,
  healthyCheckApi,
  saveLeadApi,
  plansApi,
  cepApi,
  getPropostaApi,
  pagamentoCartaoApi,
  pagamentoBoletoApi,
  pagamentoPixApi,
  planDetailsApi,
} from "./endpoints";
import useUserStore from "../storeModules/useUserStore";
import useInitialConfigStore from "../storeModules/useInitialConfigStore";
import useConsorcioStore from "../storeModules/useConsorcioStore";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../utils/setLoadingMessage";
import useBotControlStore from "../storeModules/useBotControlStore";
import findStep from "../screens/bot/stepModules/findStep";

const getApiHealthCheck = async () => {
  const url = `${baseApiURL}${healthyCheckApi}`;

  return api
    .get(url)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const getLead = async () => {
  const url = `${baseApiURL}${saveLeadApi}`;

  const { campanhaId, compraId, empresaId, categoriaId, tipo, referralcode } =
    useInitialConfigStore.getState();

  const { nome, email, telefone, rendaMensal } = useUserStore.getState().basic;

  const props = {
    nome,
    email,
    telefone,
    rendaMensal,
    campanhaId,
    compraId,
    categoriaId,
    tipo,
    empresaId,
    referralCode: referralcode,
  };

  return apiWithkey.post(url, props);
};

const getPlans = async () => {
  const url = `${baseApiURL}${plansApi}`;

  const { empresaId } = useInitialConfigStore.getState();
  const { categoriaId } = useConsorcioStore.getState();
  const { valorCredito, rendaMensal, rendaExtra } =
    useUserStore.getState().basic;
  const { LeadID } = useUserStore.getState().lead;

  const props = {
    categoriaId,
    empresaId,
    valorCredito,
    leadID: LeadID,
    valorParcelaIdeal: (rendaMensal + rendaExtra) * 0.3,
  };

  return apiWithkey.post(url, props);
};

const getCep = async () => {
  const { cep } = useUserStore.getState().contrato;
  const url = `${baseApiURL}${cepApi}?cep=${cep}`;

  return apiWithkey.get(url);
};

const getProposta = async () => {
  const url = `${baseApiURL}${getPropostaApi}`;

  const {
    bairro,
    cep,
    cidade,
    estado,
    logradouro,
    nomeRazao,
    nascimentoFundacao,
    cpfCnpj,
  } = useUserStore.getState().contrato;

  const { telefone, rendaMensal, email } = useUserStore.getState().basic;
  const { LeadID } = useUserStore.getState().lead;
  const { planoID, planoMeses, bemID } =
    useConsorcioStore.getState().selectedPlano;
  const { empresaId } = useInitialConfigStore.getState();
  const { tipoPessoa } = useUserStore.getState().contrato;

  const props = {
    bairro,
    bemID: bemID,
    cep,
    cidade,
    complemento: "",
    cpfCnpj,
    email,
    empresaId,
    estado,
    leadId: LeadID.toString(),
    logradouro,
    nascimentoFundacao: new Date(nascimentoFundacao),
    nome: nomeRazao,
    nomeRazao,
    numero: "0",
    planoID: planoID,
    planoMeses: planoMeses,
    rendaFaturamento: rendaMensal.toString(),
    rendaMensal: rendaMensal.toString(),
    telefone,
    telefoneCelular: telefone,
    termos: true,
    tipoPessoa: tipoPessoa,
  };

  return apiWithkey.post(url, props);
};

const sendPaymentCartao = async (props) => {
  const url = `${baseApiURL}${pagamentoCartaoApi}`;

  return apiWithkey.post(url, props);
};

const getPaymentBoleto = async (props) => {
  const url = `${baseApiURL}${pagamentoBoletoApi}`;
  const data = JSON.stringify(props.propostaToken);

  const config = {
    method: "post",
    url,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    data,
  };

  return apiWithkey.request(config);
};

const getPaymentPix = async (props) => {
  const url = `${baseApiURL}${pagamentoPixApi}`;
  const data = JSON.stringify(props.propostaToken);

  const config = {
    method: "post",
    url,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    data,
  };

  return apiWithkey.request(config);
};

const getPlanDetails = async () => {
  const url = `${baseApiURL}${planDetailsApi}`;

  const { planoID, bemID } = useConsorcioStore.getState().selectedPlano;
  const { LeadID } = useUserStore.getState().lead;
  const { empresaId } = useInitialConfigStore.getState();

  const props = {
    empresaID: empresaId,
    leadID: LeadID,
    planoID: planoID,
    bemID: bemID,
  };

  return apiWithkey.post(url, props);
};

export {
  getApiHealthCheck,
  getLead,
  getPlans,
  getCep,
  getProposta,
  sendPaymentCartao,
  getPaymentBoleto,
  getPaymentPix,
  getPlanDetails,
};
