import React, { useState } from "react";
import Slider from "react-slick";
import "./plan-list-carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomPrevArrow, CustomNextArrow } from "../servicesCarousel/arrow";
import catgeral from "../../assets/icons/cat-0-geral.svg";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import useBotControlStore from "../../storeModules/useBotControlStore";
import { resetStoreComprarNovaCota } from "../../storeModules/resetStoreComprarNovaCota";
import { getPlanDetails } from "../../api/requests";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../utils/setLoadingMessage";
import useInitialConfigStore from "../../storeModules/useInitialConfigStore";
import LoadingDots from "../loadingDots";

const PlanListCarousel = ({ options, onOptionClick }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const tipoPlano = useConsorcioStore((state) => state.tipoPlano);
  const { setCurrentStep } = useBotControlStore((state) => state);
  const hasDesconto = useInitialConfigStore((state) => state.desconto);

  const setConsorcioStore = useConsorcioStore(
    (state) => state.setConsorcioStore
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [{
      breakpoint: 599,
       settings: {
         slidesToShow: 1,
         centerMode: false,

     }
   }],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const handleGetPlanDetails = async () => {
    setLoadingMessage();

    setTimeout(() => {
      getPlanDetails()
        .then((planDetails) => {
          const { data } = planDetails.data;

          if (data.titulo && data.taxaAdmTotal) {
            setConsorcioStore(data, "fetchedPlanDetails");
          }

          if (hasDesconto) {
            setCurrentStep(99);
            return;
          }

          setCurrentStep(18);
        })
        .catch((error) => {
          console.log("error --->", error);
          setCurrentStep(87);
        })
        .finally(() => {
          removeLoadingMessage();
        });
    }, 500);
  };

  return (
    <div className="carousel-container">
      {options.length ? (
        <Slider {...settings}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`carousel-item ${
                tipoPlano === "CREDITO_PAGAMETADE" ? "card-pagamentade" : ""
              }`}
            >
              <button
                onClick={async () => {
                  if (option.description === "Simular Novamente") {
                    setCurrentStep(2);
                    resetStoreComprarNovaCota();
                  } else {
                    onOptionClick(option);
                    handleGetPlanDetails();
                  }
                }}
                className="carousel-button"
              >
                <div className="image-container">
                  <img
                    src={catgeral}
                    alt={`Imagem do plano para ${
                      option.bem || "o bem selecionado"
                    }`}
                    width={120}
                  />
                </div>
                <div className="option">
                  {option.description === "Simular Novamente" ? (
                    <>Simular Novamente</>
                  ) : (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: option.text }}
                    />
                  )}
                </div>
                <button
                  className={`choose-button ${
                    index === activeSlide ? "active" : ""
                  }`}
                >
                  {option.description === "Simular Novamente"
                    ? "SIMULAR"
                    : "ESCOLHER ESSE PLANO"}
                </button>
              </button>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="carousel-loading">
          <LoadingDots size="small" color="primary" />
        </div>
      )}
    </div>
  );
};

export default PlanListCarousel;
