import option from "../../assets/images/option.png";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import useBotControlStore from "../../storeModules/useBotControlStore";
import useInitialConfigStore from "../../storeModules/useInitialConfigStore";
import { ProdutosEnum, CategoriaPagamento } from "../../utils/constants";

const TypeOptions = () => {
  const options = [
    {
      text: "Imóveis",
      description: "Novos ou usados",
      imageThumb: "cat-1-imoveis.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.IMOVEL,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.IMOVEL],
        });
        window.dataLayer.push({ event: "Categoria Imoveis" });
        useBotControlStore.setState({ currentStep: 3 });
      },
    },
    {
      text: "Automóveis",
      description: "Novos ou seminovos",
      imageThumb: "cat-2-auto.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.CARRO,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.CARRO],
        });
        window.dataLayer.push({ event: "Categoria Autos" });
        useBotControlStore.setState({ currentStep: 3 });
      },
    },
    {
      text: "Motocicletas",
      description: "Nacionais ou importadas",
      imageThumb: "cat-3-motos.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.MOTO,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.MOTO],
        });
        window.dataLayer.push({ event: "Categoria Motos" });
        useBotControlStore.setState({ currentStep: 3 });
      },
    },
    {
      text: "Caminhões",
      description: "Novos ou seminovos",
      imageThumb: "cat-6-pesados.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.CAMINHAO,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.CAMINHAO],
        });
        window.dataLayer.push({ event: "Categoria Pesados" });
        useBotControlStore.setState({ currentStep: 3 });
      },
    },
    {
      text: "Pesados",
      description: "Máquinas e Equipamentos",
      imageThumb: "cat-6-maquinas-equipamentos.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.MAQUINAS,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.MAQUINAS],
        });
        window.dataLayer.push({ event: "Categoria Pesados" });
        useBotControlStore.setState({ currentStep: 3 });
      },
    },
    {
      text: "Serviços",
      description: "Festas, viagens e mais",
      imageThumb: "cat-4-servicos.svg",
      icon: option,
      action: () => {
        const consorcioData = useConsorcioStore.getState();
        const { tipoTabela } = useInitialConfigStore.getState();
        useConsorcioStore.setState({
          ...consorcioData,
          tipoBem: ProdutosEnum.SERVICO,
          categoriaId:
            CategoriaPagamento[consorcioData.tipoPlano][ProdutosEnum.SERVICO],
        });
        window.dataLayer.push({ event: "Categoria Servicos" });

        if (tipoTabela === "completa") {
          useBotControlStore.setState({ currentStep: 93 });
        } else {
          useBotControlStore.setState({ currentStep: 3 });
        }
      },
    },
  ];

  return options;
};

export default TypeOptions;
