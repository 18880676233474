import useConsorcioStore from "../../storeModules/useConsorcioStore";
import { TipoSimulacaoEnum } from "../../utils/constants";
import { CategoriaPagamento } from "../../utils/constants";

const ServicesPlans = () => {
  const options = [
    {
      text: "SIMULAR PLANO INTEGRAL",
      nextStep: 3,
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][
              consorcioData.tipoBem
            ],
        });
        window.dataLayer.push({ event: "Plano Integral" });
      },
    },
    {
      text: "ESCOLHER OUTRO PRODUTO",
      nextStep: 2,
    },
  ];

  return options;
};

export default ServicesPlans;
