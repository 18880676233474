import useUserStore from "../../../storeModules/useUserStore";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import findStep from "./findStep";

const errorSteps = [
  {
    name: "error-no-plans",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `<b>${userBasicName}, não encontrei nenhum plano com este valor.</b> Por favor, faça uma nova simulação.`;
    },
    options: [
      {
        text: "SIMULAR NOVAMENTE",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("credit-value").index,
          });
        },
      },
      {
        text: "FALAR COM OS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
  },
  {
    name: "error-renda-insuficiente",
    sender: "bot",
    text: () => "Você tem alguma renda extra ou familiar?",
    options: [
      {
        text: "NÃO TENHO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("sem-renda-extra").index,
          });
        },
      },
      {
        text: "TENHO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("com-renda-extra").index,
          });
        },
      },
      {
        text: "FALAR COM OS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
  },
];

export default errorSteps;
