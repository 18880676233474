import { useEffect, useRef } from "react";

const useOnceEffect = (callBacks) => {
  const triggered = useRef(false);

  useEffect(() => {
    if (!triggered.current) {
      triggered.current = true;
      callBacks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnceEffect;
