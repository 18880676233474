import useBotControlStore from "../storeModules/useBotControlStore";
import LoadingDots from "../components/loadingDots";

const setLoadingMessage = () => {
  const messages = useBotControlStore.getState().messages;
  const loadingMessage = {
    id: "step-bot-loading",
    sender: "bot",
    text: "",
    component: <LoadingDots size="small" color="primary" />,
  };

  useBotControlStore.setState({
    messages: [...messages, loadingMessage],
    isBotLoading: true,
  });
};

const removeLoadingMessage = () => {
  const messages = useBotControlStore.getState().messages;

  useBotControlStore.setState({
    messages: messages.filter((message) => message.id !== "step-bot-loading"),
    isBotLoading: false,
  });
};

export { setLoadingMessage, removeLoadingMessage };
