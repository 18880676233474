import React from "react";
import IconsWhatsapp from "../../assets/icons/ico-whatsapp.png";

const getClassName = (index, currentStep) => {
  const stepClassMapping = {
    110: { 0: "primary", 1: "primary", 2: "primary", 3: "btwhatsapp" },
    109: { 0: "primary", 1: "primary", 2: "primary", 3: "btwhatsapp" },
    103: { 0: "primary", 1: "btwhatsapp" },
    89: { 0: "btwhatsapp" },
    88: { 0: "primary", 1: "primary", 2: "primary", 3: "btwhatsapp" },
    87: { 0: "primary", 1: "btwhatsapp" },
    29: { 1: "secondary", 2: "secondary", 3: "btwhatsapp" },
    31: { 1: "secondary", 2: "secondary", 3: "tertiary" },
    18: { 1: "secondary", 2: "secondary", 3: "tertiary" },
    65: { 1: "primary", 2: "secondary", 3: "primary" },
    40: { 1: "primary", 2: "secondary", 3: "primary" },
    59: { 1: "secondary" },
  };

  const defaultClass = stepClassMapping[currentStep]?.[index] || "";
  return defaultClass;
};

const Options = ({ options, currentStep, handleOptionClick }) => {
  return options.map((option, index) => {
    const className = getClassName(index, currentStep);

    return (
      <button
        key={index}
        className={`option-button ${className} ${
          option.text === "FALAR COM OS ESPECIALISTAS" ? "btwhatsapp" : ""
        }`}
        onClick={() => {
          handleOptionClick(option);

          if (option.action) {
            option.action();
          }
        }}
      >
        {(className === "btwhatsapp" ||
          option.text === "FALAR COM OS ESPECIALISTAS") && (
          <figure className="image">
            <img
              alt="Atendimento via Whatsapp"
              className="Media"
              src={IconsWhatsapp}
            />
          </figure>
        )}
        <span>{option.text}</span>
      </button>
    );
  });
};

export default Options;
