const basicNameRegex = /^[a-zA-ZÀ-ÿ\s]+$/;

const nomeRazaoRegex = /^[a-zA-ZÀ-ÿ]+( [a-zA-ZÀ-ÿ]+)+( )*$/;

const removeNonDigits = /\D/g;

const telefoneRegex = /^\d{2}(?!.*(\d)\1{8})\d{5}\d{4}$/; //Last 9 numbers are not repeated

const allRepeatedNumbersRegex = /^(?!.*(\d)\1{7})\d+$/;

export {
  basicNameRegex,
  nomeRazaoRegex,
  removeNonDigits,
  telefoneRegex,
  allRepeatedNumbersRegex,
};
