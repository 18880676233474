const ErrorPage = () => {
  return (
    <div>
      <h1>Página de erro</h1>
      <p>Oops! Essa página não existe.</p>
    </div>
  );
};

export default ErrorPage;
