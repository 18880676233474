import useConsorcioStore from "../../storeModules/useConsorcioStore";
import { TipoSimulacaoEnum } from "../../utils/constants";
import { CategoriaPagamento } from "../../utils/constants";

const TypesOfPlans = () => {
  const options = [
    {
      text: "PLANO INTEGRAL",
      nextStep: 9,
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][
              consorcioData.tipoBem
            ],
        });
        window.dataLayer.push({ event: "Plano Integral" });
      },
    },
    {
      text: "PLANO PAGA METADE",
      nextStep: 9,
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_PAGAMETADE][
              consorcioData.tipoBem
            ],
        });
        window.dataLayer.push({ event: "Plano Paga Metade" });
      },
    },
  ];

  return options;
};

export default TypesOfPlans;
