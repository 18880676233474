import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const botControlInitialState = {
  messages: [],
  currentStep: -1,
  showPaymentModal: false,
  isBotLoading: false,
};

export const useBotControlStore = create()(
  devtools(
    immer((set) => ({
      ...botControlInitialState,
      setMessages: (message) => {
        set(
          (state) => {
            state.messages = [...state.messages, message];
          },
          true,
          "setMessages"
        );
      },
      setCurrentStep: (step) => {
        set(
          (state) => {
            state.currentStep = step;
          },
          true,
          "setCurrentStep"
        );
      },
      setShowPaymentModal: (show) => {
        set(
          (state) => {
            state.showPaymentModal = show;
          },
          true,
          "setShowPaymentModal"
        );
      },
    })),
    { enabled: true, name: "useBotControlStore" }
  )
);

export default useBotControlStore;
