export const userValidAge = (date, userAge) => {
  const birthDate = new Date(date);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Check if the current month is before the birth month
  if (currentDate.getMonth() < birthDate.getMonth()) {
    age--;
  }
  // If the current month is the same as the birth month, check if the current day is before the birth day
  else if (
    currentDate.getMonth() === birthDate.getMonth() &&
    currentDate.getDate() < birthDate.getDate()
  ) {
    age--;
  }

  if (userAge === "underAge") return age < 18;

  if (userAge === "aboveAge") return age >= 90;
};

export default userValidAge;
