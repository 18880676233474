// const baseApiURL = "https://vendas-api-hml.mycon.com.br";
const baseApiURL = "https://mycon-api.com.br";
// const baseApiURL = "https://cd-dev.mycon.com.br";
const healthyCheckApi = "/hc";
const saveLeadApi = "/api/lead";
const plansApi = "/api/plano";
const cepApi = "/api/endereco";
const getPropostaApi = "/api/proposta";
const pagamentoCartaoApi = "/api/pagamento/cartao";
const pagamentoBoletoApi = "/api/pagamento/boleto";
const pagamentoPixApi = "/api/pagamento/pix";
const planDetailsApi = "/api/plano/detalhes";

export {
  baseApiURL,
  healthyCheckApi,
  saveLeadApi,
  plansApi,
  cepApi,
  getPropostaApi,
  pagamentoCartaoApi,
  pagamentoBoletoApi,
  pagamentoPixApi,
  planDetailsApi,
};
