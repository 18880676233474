import React from "react";
import "./styles.scss";

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return <div className={`${className} custom-prev-arrow`} onClick={onClick} />;
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return <div className={`${className} custom-next-arrow`} onClick={onClick} />;
};

export { CustomPrevArrow, CustomNextArrow };
