import { useForm, Controller } from "react-hook-form";
import useUserStore from "../../storeModules/useUserStore";
import {
  basicNameRegex,
  nomeRazaoRegex,
  telefoneRegex,
  allRepeatedNumbersRegex,
} from "../../utils/regex";
import InputMessage from "./inputMessage";
import { NumericFormat, PatternFormat } from "react-number-format";
import useBotControlStore from "../../storeModules/useBotControlStore";
import steps from "../../screens/bot/stepModules/steps";
import isEmail from "../../utils/isEmail";
import capitalizeFirstCharOfSegments from "../../utils/capitalizeFirstCharOfSegments";
import { cpf, cnpj } from "cpf-cnpj-validator";
import removeSpecialCharacters from "../../utils/removeSpecialCharacters";
import removeMoneyMask from "../../utils/removeMoneyMask";
import userValidAge from "../../utils/userValidAge";
import addMoneyMask from "../../utils/addMoneyMask";
import addPhoneMask from "../../utils/addPhoneMask";
import addCepMask from "../../utils/addCepMask";
import dayjs from "dayjs";
import { valoresSimulacao } from "../../utils/constants";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import removeLastTwoDigits from "../../utils/removeLastTwoDigits";

const BotInput = ({ inputType, submitAction }) => {
  const currentStep = useBotControlStore((state) => state.currentStep);
  const {
    basicUserName,
    userCredit,
    userRendaMensal,
    userRendaExtra,
    userEmail,
    userTelefone,
  } = useUserStore((state) => state.basic);

  const {
    userNomeRazao,
    tipoPessoa,
    cpfCnpj,
    cep,
    nascimentoFundacao,
    logradouro,
    bairro,
  } = useUserStore((state) => state.contrato);

  const { setUserBasicInfo, setUserContratoInfo } = useUserStore(
    (state) => state
  );

  const { tipoPlano, tipoBem } = useConsorcioStore((state) => state);

  const { setMessages, setCurrentStep } = useBotControlStore((state) => state);

  const isPessoaFisica = tipoPessoa === 0;

  const createTextMessage = (text) => {
    let textMessage = text;

    if (
      inputProps[inputType].name === "userCreditValue" ||
      inputProps[inputType].name === "userRendaMensal" ||
      inputProps[inputType].name === "userRendaExtra"
    ) {
      textMessage = addMoneyMask(removeLastTwoDigits(text));
    }

    if (inputProps[inputType].name === "userTelefone") {
      textMessage = addPhoneMask(text);
    }

    if (inputProps[inputType].name === "userCep") {
      textMessage = addCepMask(text);
    }

    if (inputProps[inputType].hasDisplayTitle) {
      textMessage = `<p title=${textMessage}>${textMessage}</p>`;
    }

    return textMessage;
  };

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userBasicName: "",
    },
  });

  const onSubmit = () => {
    setMessages({
      sender: "user",
      text: createTextMessage(getValues(inputProps[inputType].name)),
    });
    setCurrentStep(steps[currentStep].nextStep);

    if (steps[currentStep].action) {
      submitAction();
    }
  };

  const inputProps = {
    userBasicName: {
      name: "userBasicName",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {
          value: (value) => {
            return basicNameRegex.test(value) || "Está correto? 🤔";
          },
        },
      },
      mask: "",
      change: (value) => {
        const capitalizedName = capitalizeFirstCharOfSegments(value);
        setUserBasicInfo(capitalizedName, "nome");
        return capitalizedName;
      },
      value: basicUserName,
      inputStyle: "text",
    },
    userCreditValue: {
      name: "userCreditValue",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {},
        min: {
          value: valoresSimulacao[tipoPlano][tipoBem].min * 100,
          message: `O crédito mínimo é de ${addMoneyMask(
            valoresSimulacao[tipoPlano][tipoBem].min
          )}`,
        },
        max: {
          value: valoresSimulacao[tipoPlano][tipoBem].max * 100,
          message: `O crédito máximo é de R$${addMoneyMask(
            valoresSimulacao[tipoPlano][tipoBem].max
          )}`,
        },
      },
      format: "",
      change: (value) => {
        const cleanValue = removeMoneyMask(value);
        setUserBasicInfo(removeLastTwoDigits(cleanValue), "valorCredito");
        return cleanValue;
      },
      value: userCredit,
      inputStyle: "money",
    },
    userRendaMensal: {
      name: "userRendaMensal",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {},
        min: {
          value: 10000,
          message: "Valor inválido",
        },
      },
      format: "",
      change: (value) => {
        const cleanValue = removeMoneyMask(value);
        setUserBasicInfo(removeLastTwoDigits(cleanValue), "rendaMensal");
        return cleanValue;
      },
      value: userRendaMensal,
      inputStyle: "money",
    },
    userRendaExtra: {
      name: "userRendaExtra",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {},
        min: {
          value: 10000,
          message: "Valor inválido",
        },
      },
      format: "",
      change: (value) => {
        const cleanValue = removeMoneyMask(value);
        setUserBasicInfo(removeLastTwoDigits(cleanValue), "rendaExtra");
        return cleanValue;
      },
      value: userRendaExtra,
      inputStyle: "money",
    },
    userEmail: {
      name: "userEmail",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {
          value: (value) => isEmail(value) || "E-mail inválido 🤔",
        },
        maxLength: {
          value: 60,
          message: "Numero máximo de caracteres excedido",
        },
      },
      format: "",
      change: (value) => {
        setUserBasicInfo(value, "email");
        return value;
      },
      value: userEmail,
      inputStyle: "text",
      hasDisplayTitle: true,
    },
    userTelefone: {
      name: "userTelefone",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {
          validPhoneNumber: (number) => {
            return telefoneRegex.test(number) || "Telefone inválido 🤔";
          },
          minSize: (value) => {
            const onlyNumbers = value.replace(/\D/g, "");
            return onlyNumbers.length >= 11 || "Telefone inválido 🤔";
          },
        },
      },
      format: "(##) #####-####",
      mask: "_",
      change: (value) => {
        const cleanValue = removeSpecialCharacters(value);
        setUserBasicInfo(cleanValue, "telefone");
        return cleanValue;
      },
      value: userTelefone,
      inputStyle: "mask",
    },
    userContratoNomeRazao: {
      name: "userContratoNomeRazao",
      rules: {
        required: {
          value: true,
          message: "Preencha para continuar! 😊",
        },
        validate: {
          value: (value) => {
            const nomeTitularErrorMessage = isPessoaFisica
              ? "Nome inválido"
              : "Razão Social inválida";

            return nomeRazaoRegex.test(value) || nomeTitularErrorMessage;
          },
        },
        maxLength: {
          value: 30,
          message: "Numero máximo de caracteres excedido 🤔",
        },
      },
      mask: "",
      change: (value) => {
        const capitalizedName = capitalizeFirstCharOfSegments(value);
        setUserContratoInfo(capitalizedName, "nomeRazao");
        return capitalizedName;
      },
      value: userNomeRazao,
      inputStyle: "text",
    },
    userCpfCnpj: {
      name: "userCpfCnpj",
      rules: {
        required: { value: true, message: "Preencha para continuar! 😊" },
        validate: {
          value: (value) => {
            const cleanValue = removeSpecialCharacters(value);
            if (isPessoaFisica) {
              return (cleanValue && cpf.isValid(cleanValue)) || "CPF inválido";
            }

            return (value && cnpj.isValid(cleanValue)) || "CNPJ inválido";
          },
        },
      },
      format: isPessoaFisica ? "###.###.###-##" : "##.###.###/####-##",
      mask: "_",
      change: (value) => {
        setUserContratoInfo(value, "cpfCnpj");
        return value;
      },
      value: cpfCnpj,
      inputStyle: "mask",
    },
    userCep: {
      name: "userCep",
      rules: {
        required: { value: true, message: "Preencha para continuar! 😊" },
        validate: {
          value: (value) => {
            const cleanValue = removeSpecialCharacters(value);
            return allRepeatedNumbersRegex.test(cleanValue) || "CEP inválido";
          },
        },
      },
      format: "#####-###",
      mask: "_",
      change: (value) => {
        const cleanValue = removeSpecialCharacters(value);
        setUserContratoInfo(cleanValue, "cep");
        return cleanValue;
      },
      value: cep,
      inputStyle: "mask",
    },
    userNascimentoFundacao: {
      name: "userNascimentoFundacao",
      rules: {
        validate: {
          value: (value) => {
            if (!value) return;

            const [day, month, year] = value.split("/");
            const dateToDayJs = `${month}/${day}/${year}`;
            let cleanValue = "";

            if (value) {
              cleanValue = removeSpecialCharacters(value);
            }

            if (!value || cleanValue.length < 8) {
              return "Preencha para continuar! 😊";
            }

            const dateDay = value.split("/")[0];
            const dateMonth = value.split("/")[1];
            const dateYear = value.split("/")[2];

            if (
              (!dayjs(value).isValid() && dayjs(value).year() <= 1900) ||
              dateDay > 31 ||
              dateDay <= 0 ||
              dateMonth > 12 ||
              dateMonth <= 0 ||
              dateYear < 1900 ||
              dateYear > dayjs(new Date()).year()
            ) {
              return "Data inválida 🤔";
            }

            if (
              isPessoaFisica &&
              userValidAge(dayjs(dateToDayJs).format("YYYY/MM/DD"), "underAge")
            ) {
              return "Menor de 18 anos não pode comprar :(";
            }

            if (
              dayjs(value) < dayjs("1900-01-01") ||
              dayjs(value) > dayjs(new Date()) ||
              (isPessoaFisica && userValidAge(dayjs(value), "aboveAge"))
            ) {
              return "Data inválida 🤔";
            }
          },
        },
      },
      format: "##/##/####",
      mask: "_",
      change: (value) => {
        setUserContratoInfo(value, "nascimentoFundacao");

        if (value === "__/__/____") {
          return "";
        } else {
          return value;
        }
      },
      value: nascimentoFundacao,
      inputStyle: "mask",
    },
    userStreet: {
      name: "userStreet",
      rules: {
        required: { value: true, message: "Preencha para continuar! 😊" },
      },
      change: (value) => {
        setUserContratoInfo(value, "logradouro");
        return value;
      },
      value: logradouro,
      inputStyle: "text",
    },
    userNeighborhood: {
      name: "userNeighborhood",
      rules: {
        required: { value: true, message: "Preencha para continuar! 😊" },
      },
      change: (value) => {
        setUserContratoInfo(value, "bairro");
        return value;
      },
      value: bairro,
      inputStyle: "text",
    },
  };

  return (
    <>
      <div
        className={`input-container ${
          errors[inputProps[inputType]] ? "input-container__error" : ""
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={inputProps[inputType].name}
            rules={inputProps[inputType].rules}
            render={({ field: { onChange, onBlur } }) => (
              <>
                {inputProps[inputType].inputStyle === "text" && (
                  <input
                    autoFocus
                    mask={inputProps[inputType].mask}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(inputProps[inputType].change(e.target.value));
                    }}
                    value={inputProps[inputType].value}
                    type="text"
                    placeholder="Escreva aqui..."
                  />
                )}

                {inputProps[inputType].inputStyle === "money" && (
                  <NumericFormat
                    autoFocus
                    value={inputProps[inputType].value}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={"R$ "}
                    onChange={(e) => {
                      onChange(inputProps[inputType].change(e.target.value));
                    }}
                    placeholder="Escreva aqui..."
                  />
                )}

                {inputProps[inputType].inputStyle === "mask" && (
                  <PatternFormat
                    autoFocus
                    format={inputProps[inputType].format}
                    mask={inputProps[inputType].mask}
                    allowEmptyFormatting
                    onChange={(e) => {
                      onChange(inputProps[inputType].change(e.target.value));
                    }}
                    value={inputProps[inputType].value}
                  />
                )}
              </>
            )}
          />
          <span
            className={`input-arrow ${
              formState.isValid ? "" : "input-arrow__disabled"
            }`}
            onClick={() => onSubmit()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path
                fill="#5452fb"
                d="M1.1 21.757l22.7-9.73L1.1 2.3l.012 7.912 13.623 1.816-13.623 1.817-.01 7.912z"
              ></path>
            </svg>
          </span>
        </form>
      </div>
      <InputMessage errors={errors} inputType={inputType} />
    </>
  );
};

export default BotInput;
