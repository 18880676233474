import useConsorcioStore, { consorcioInitialState } from "./useConsorcioStore";
import useUserStore, { userInitialState } from "./useUserStore";

const resetStoreComprarNovaCota = () => {
  const { tipoPlano } = useConsorcioStore.getState();

  useConsorcioStore.setState({
    ...consorcioInitialState,
    tipoPlano,
  });

  useUserStore.setState({
    ...userInitialState,
  });
};

export { resetStoreComprarNovaCota };
