// Create a function to add a mask like (11) 11111-1111 to a phone number
const addPhoneMask = (number) => {
  const maskedNumber = `(${number.slice(0, 2)}) ${number.slice(
    2,
    7
  )}-${number.slice(7)}`;

  return maskedNumber;
};

export default addPhoneMask;
