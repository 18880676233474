import useConsorcioStore from "../../storeModules/useConsorcioStore";

const GroupInfoOptions = () => {
  const { fetchedPlanDetails } = useConsorcioStore.getState();

  const { planoTaxas } = fetchedPlanDetails;

  return planoTaxas;

  // const groupData = {
  //   taxa: `${TaxaAdmTotal}% com 1% de antecipação em 12 meses.`,
  //   imoveisN: 2089,
  //   prazoMaximo: Titulo,
  //   numeroParticipantes: 999,
  //   fundoReserva: false,
  //   seguro: "Após Contemplação",
  //   reajusteAnual: "IPCA",
  //   sorteioMensal: "1 contemplação por sorteio",
  //   lances:
  //     "Tantas quanto o saldo do grupo permitir por lance livre e lance fixo de 20% (na respectiva ordem)",
  //   lanceFixo: 20,
  //   lanceLivre: 100,
  //   pagamentoLanceFacilitado:
  //     "É possível utilizar até 20% do próprio crédito para pagar o lance ofertado, no tipo fixo ou livre.",
  // };

  // return `
  //     <b>Taxa de Administração</b><br/>
  //     Total do Plano: <b> ${groupData.taxa}</b><br/><br/>
  //     <b>Informações do Grupo:</b><br/>
  //     Imóveis N: <b> ${groupData.imoveisN}</b><br/>
  //     Prazo Máximo: <b>${groupData.prazoMaximo} Meses</b><br/>
  //     Número Participantes: <b>${groupData.numeroParticipantes}</b><br/>
  //     Fundo de Reserva:<b> ${groupData.fundoReserva ? "Sim" : "Não"}</b><br/>
  //     Seguro:<b> ${groupData.seguro}</b><br/>
  //     Reajuste Anual: <b>${groupData.reajusteAnual}</b><br/><br/>

  //     <b> Formas de Contemplação</b><br/>

  //     Sorteio Mensal:<b> ${groupData.sorteioMensal}</b><br/>
  //     Lances:<b> ${groupData.lances}</b><br/><br/>

  //     <b>Tipos de Lance</b><br/>
  //     Fixo: <b>${groupData.lanceFixo}% do Crédito<br/>
  //     Livre: até<b> ${groupData.lanceLivre}% do Saldo</b><br/><br/>
  //     <b>Pagamento de Lance Facilitado</b><br/>

  //   ${groupData.pagamentoLanceFacilitado}
  //   `;
};

export default GroupInfoOptions;
