import axios from "axios";

const api = axios.create();
const apiWithkey = axios.create();

apiWithkey.interceptors.request.use(function (config) {
  config.headers["ApiKey"] = "9W3B7108-2E46-5S4C-LK29-BTY263687422";
  return config;
});

export { api, apiWithkey };
